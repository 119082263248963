<template>
  <component
    :is="tag"
    class="content-container"
  >
    <heading
      v-if="title"
      :level="titleLevel"
      class="content-container__title"
    >
      {{ title }}
      <mds-tag v-if="fileTypeTag">
        {{ fileTypeTag }}
      </mds-tag>
    </heading>
    <p
      v-if="description"
      class="content-container__description"
    >
      {{ description }}
    </p>
    <div
      v-if="hasSlot('action')"
      class="content-container__action"
    >
      <slot name="action" />
    </div>
    <div
      :class="{ 'content-container__content--no-margin': noMargin }"
      class="content-container__content"
    >
      <slot />
    </div>
  </component>
</template>

<script>
import { MdsTag } from '@mds/tag'
import Heading from '../Heading/Heading'

export default {
  components: {
    Heading,
    MdsTag,
  },
  props: {
    description: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: 'section',
    },
    title: {
      type: String,
      default: null,
    },
    titleLevel: {
      type: Number,
      default: 2,
    },
    fileTypeTag: {
      type: String,
      default: null,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hasSlot (name) {
      return !!this.$slots[name]
    },
  },
}
</script>

<style lang="scss">
.content-container {
  display: grid;
  gap: $mds-space-2-x 0;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.content-container__title {
  @include mds-level-4-heading($bold: true);
  margin: 0;
  grid-column: 1 / span 1;
}

.content-container__title {
  .mds-tag___radar {
    margin-left: 0.5rem;
  }
}

.content-container__description {
  @include mds-body-text-l();
  margin: 0;
  grid-column: 1 / span 1;
}

.content-container__action {
  grid-column: 2 / span 1;
  align-self: center;
}

.content-container__content {
  grid-column: 1 / -1;
  max-width: 100%;
  margin-top: $mds-space-1-x;

  &--no-margin{
    margin-top: 0;
  }
}
</style>
