<script>
export default {
  functional: true,
  props: {
    level: {
      type: Number,
      required: true,
      validator: value => [1, 2, 3, 4, 5, 6].includes(value),
    },
  },
  render (h, { props, data, slots }) {
    return h(
      `h${props.level}`,
      data, // So attributes aren't ignored
      slots().default,
    )
  },
}
</script>
